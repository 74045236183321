import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  contactsList?: string[]
  googleMapsURL?: string
  languageCode: string
}

export const ContactDetails = memo(function ContactDetails({
  address,
  contactsList,
  googleMapsURL,
  languageCode,
}: Props) {
  return (
    <Container row space="between" tag="section" wrap>
      <Cell>
        <Label>
          <Line />
          {useVocabularyData('where-we-are', languageCode)}
        </Label>

        {address ? (
          <Address dangerouslySetInnerHTML={{ __html: address }} />
        ) : null}

        {googleMapsURL ? (
          <GoogleMapsCTA href={googleMapsURL} rel="noreferrer" target="_blank">
            {useVocabularyData('open-in-google-maps', languageCode)}
          </GoogleMapsCTA>
        ) : null}
      </Cell>

      {contactsList
        ? contactsList.map((item: any, index) => (
            <Cell key={index}>
              {item.title ? (
                <Label>
                  <Line />
                  {item.title}
                </Label>
              ) : null}

              {item.phone ? (
                <Anchor
                  href={`tel:${item.phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData('phone', languageCode)}: ${item.phone}`}
                </Anchor>
              ) : null}

              {item.mobile ? (
                <>
                  <Divider />
                  <Anchor
                    href={`tel:${item.mobile}`}
                    onClick={() => {
                      if (isMobile) {
                        typeof window.gtag !== 'undefined' &&
                          window.gtag('event', 'Click', {
                            event_category: 'Website',
                            event_label: 'Phone Number',
                          })
                      }
                    }}
                  >
                    {`${useVocabularyData('mobile', languageCode)}: ${
                      item.mobile
                    }`}
                  </Anchor>
                </>
              ) : null}

              {item.email ? (
                <>
                  <Divider />
                  <Anchor
                    href={`mailto:${item.email}`}
                    onClick={() => {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Email Address',
                        })
                    }}
                  >
                    {`${useVocabularyData('email', languageCode)}: ${
                      item.email
                    }`}
                  </Anchor>
                </>
              ) : null}
            </Cell>
          ))
        : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 9.375rem auto 13.4375rem;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Cell = styled.div`
  width: 33.333%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 2.5rem;
    text-align: center;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Label = styled.div`
  max-width: 20rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  margin-bottom: 2.6875rem;

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 1.5rem;
    margin-bottom: 1.125rem;

    > div {
      margin-right: auto;
      margin-left: auto;
    }
  }
`

const Address = styled.div`
  @media (max-width: 1023px) {
    br {
      content: ' ';
      &:after {
        content: ' ';
      }
    }
  }
`

const GoogleMapsCTA = styled.a`
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryDark};
  }
`

const Divider = styled.div``

const Anchor = styled.a`
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`
