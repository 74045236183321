import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  variant?: Variant
}

export const Line = memo(function Line({ variant = 'default' }: Props) {
  return <Container variant={variant} />
})

const Container = styled(FlexBox)<ContainerProps>`
  width: 4.625rem;
  height: 0.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-bottom: 1.25rem;

  ${({ theme, variant }) => {
    switch (variant) {
      case 'invert':
        return css`
          background: ${theme.colors.variants.neutralLight4};
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'invert'
